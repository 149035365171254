import { v4 as uuidv4 } from 'uuid'
import { ACCION_EJEC } from '@/utils/consts'

export default {
  async rowArticulo (Vue, idarticulo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.articulo)
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.articulo.idarticulo.eq(idarticulo)
        )
      )
      .exec()
    )[0]
  },
  async rowMaterialSistema (Vue, idmaterialSistema) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.material_sistema)
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.material_sistema.idmaterial_sistema.eq(idmaterialSistema)
        )
      )
      .exec()
    )[0]
  },
  async _insertarAcciones (Vue, idparteTrabajo, idordenTrabajoMatsist, acciones) {
    for (let idmaccion of acciones) {
      const maccion = await Vue.$offline.maccion.row(idmaccion)
      await Vue.$offline.accionEjec.insertSync({
        idaccion_ejec: uuidv4(),
        idorden_trabajo_matsist: idordenTrabajoMatsist,
        idmaccion,
        estado: 1,
        idest_accion_ejec: ACCION_EJEC.estados.pendiente,
        facturable: maccion.maccion.facturable,
        idparte_trabajo: idparteTrabajo
      })
    }
  },
  async addMaterialSistemaParte (Vue, idparteTrabajo, idmaterialSistema, formData) {
    const parteTrabajo = await Vue.$offline.parteTrabajo.row(idparteTrabajo)
    const idordenTrabajo = parteTrabajo.parte_trabajo.idorden_trabajo
    await Vue.$offline.ordenTrabajoSubsis.insertOrdenTrabajoSubsis(
      idordenTrabajo, formData.idsubsis_idtsubsis.id
    )
    let idordenTrabajoMatsist
    const ordenTrabajoMatsist = await Vue.$offline.ordenTrabajoMatsist.selectPorIdmaterialSistema(
      idordenTrabajo, idmaterialSistema
    )
    if (ordenTrabajoMatsist.length > 0) {
      idordenTrabajoMatsist = ordenTrabajoMatsist[0].orden_trabajo_matsist.idorden_trabajo_matsist
    } else {
      idordenTrabajoMatsist = uuidv4()
      await Vue.$offline.ordenTrabajoMatsist.insertSync({
        idorden_trabajo_matsist: idordenTrabajoMatsist,
        estado: 1,
        idmaterial_sistema: idmaterialSistema,
        idorden_trabajo: idordenTrabajo,
      })
    }
    await Vue.$offline.parteTrabajoMatsist.insertSync({
      idparte_trabajo_matsist: uuidv4(),
      idparte_trabajo: idparteTrabajo,
      idorden_trabajo_matsist: idordenTrabajoMatsist,
      estado: 1,
    })
    // acciones
    if (formData.acciones.length > 0) {
      await this._insertarAcciones(Vue, idparteTrabajo, idordenTrabajoMatsist, formData.acciones)
    }
  },
  async _addMaterialNuevoParteUnidades (Vue, idarticulo, unidades, idparteTrabajo, idsubsis, acciones) {
    const material = await Vue.$offline.parteTrabajoMatsist.insertMaterialParteOtYSistema(
      idarticulo, unidades, idparteTrabajo, idsubsis
    )
    if (acciones.length > 0) {
      await this._insertarAcciones(Vue, idparteTrabajo, material.idordenTrabajoMatsist, acciones)
    }
    return material.idmaterialSistema
  },
  async addMaterialNuevoParte (Vue, idparteTrabajo, idarticulo, unidades, formData) {
    const parteTrabajo = await Vue.$offline.parteTrabajo.row(idparteTrabajo)
    const idordenTrabajo = parteTrabajo.parte_trabajo.idorden_trabajo
    const ordenTrabajo = await Vue.$offline.ordenTrabajo.row(idordenTrabajo)
    let idsMateriales = []
    let idsubsis
    // no existe el subsistema
    if (formData.idsubsis_idtsubsis.tipo === 'tsubsis') {
      idsubsis = uuidv4()
      await Vue.$offline.subsis.insertSync({
        idsubsis,
        idsistema: ordenTrabajo.orden_trabajo.idsistema,
        idtsubsis: formData.idsubsis_idtsubsis.idtsubsis,
        descripcion: formData.subsis_descripcion,
        codigo: formData.subsis_codigo,
        estado: 1,
      })
    } else {
      idsubsis = formData.idsubsis_idtsubsis.id
    }
    await Vue.$offline.ordenTrabajoSubsis.insertOrdenTrabajoSubsis(idordenTrabajo, idsubsis)
    if (formData.desglosar) {
      let count = 0
      while (count < formData.unidades) {
        idsMateriales.push(
          await this._addMaterialNuevoParteUnidades(
            Vue, idarticulo, 1, idparteTrabajo, idsubsis, formData.acciones
          )
        )
        count++
      }
    } else {
      idsMateriales.push(
        await this._addMaterialNuevoParteUnidades(
          Vue, idarticulo, unidades, idparteTrabajo, idsubsis, formData.acciones
        )
      )
    }
    return idsMateriales
  }
}
